import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { pushEvent } from '../utils/ga';
const PracticeContext = createContext(null);

const sanitizeText = (text) => {
  return text.toLowerCase()
    .replace(/[^a-zA-Z0-9\s]/g, '')
    // .split(/\s+/).slice(0, 15).join(' ');
};
const allowedKeys = (key) => key.length === 1 && key.match(/[a-zA-Z0-9\s]/) !== null;
export const PracticeProvider = ({ children }) => {
  const [text, setText] = useState("..");
  const [userInput, setUserInput] = useState('');
  const [startTime, setStartTime] = useState(null);
  const [wordCount, setWordCount] = useState(0);
  const [isFinished, setIsFinished] = useState(false);
  const [correctChars, setCorrectChars] = useState(0);
  const [inCorrectChars, setInCorrectChars] = useState(0);
  const [recording, setRecording] = useState([]);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [cursorPositionReplay, setCursorPositionReplay] = useState(null);
  const [retry, setRetry] = useState(false);
  const [replay, setReplay] = useState([]);
  const [material, setMaterial] = useState({});
  const [completed, setCompleted] = useState(false);
  const [testCount, setTestCount] = useState(() => {
    const savedCount = localStorage.getItem('testCount');
    return savedCount ? parseInt(savedCount, 10) : 0;
  });

  useEffect(() => {
    localStorage.setItem('testCount', testCount.toString());
    pushEvent('finish_typing', {
      'event_category': 'Practice',
      'event_label': 'User finished typing',
      'count': testCount
    });
  }, [testCount]);

  const inputRef = useRef(null);
  const doRetry = useCallback(() => {
    setRetry(true);
    setCursorPositionReplay(null);
    setReplay(recording);
    setStartTime(null);
    setRecording([]);
    setUserInput('');
    setIsFinished(false);
    setCorrectChars(0);
    setInCorrectChars(0);
    inputRef.current?.focus();
  }, [recording]);

  const reset = useCallback(() => {
    setText("..");
    setUserInput('');
    setStartTime(null);
    setWordCount(0);
    setIsFinished(false);
    setCorrectChars(0);
    setInCorrectChars(0);
    setRecording([]);
    setCursorPosition(null);
    setCursorPositionReplay(null);
    setRetry(false);
    setReplay([]);
    setMaterial({});
    setCompleted(false);
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if(material.content !== undefined)
      return;
    const abortController = new AbortController();
    fetch(`/quotes/${Math.floor(Math.random() * 500) + 1}.json`, { signal: abortController.signal })
      .then(response => response.json())
      .then( (data) => {
        setMaterial(data);
        setText(sanitizeText(data.content))
      })
      .catch(error => {
        if (error.name !== 'AbortError') {
          console.error('Error:', error);
        }
      });
    return () => abortController.abort();
  }, [material]);


  useEffect(() => {
    if (userInput.length === 1) {
      pushEvent('start_typing', {
        'event_category': 'Practice',
        'event_label': 'User started typing'
      });
      setStartTime(new Date());
    } else if (userInput === text) {
      setTestCount(prev => prev + 1);
      setIsFinished(true);
    }
  }, [userInput, text]);

  useEffect(() => {
    if (isFinished) {
      const timeElapsed = (new Date() - startTime) / 1000;
      const wordsTyped = text.trim().split(/\s+/).filter(Boolean).length;
      const finalWPM = Math.round((wordsTyped / timeElapsed) * 60);
      setWordCount(Math.max(finalWPM, 0));
    }
  }, [startTime, isFinished, text]);

  useEffect(() => {
    if (retry && startTime !== null && replay.length > 0) {
      const interval = setInterval(() => {
        const {ms, pos} = replay[0];
        const timePassed = new Date().getTime() - startTime.getTime();
        if (timePassed >= ms) {
          setCursorPositionReplay(pos);
          setReplay(prev => prev.slice(1));
        }
      }, 100);
      return () => clearInterval(interval);
    }
  }, [retry, startTime, replay]);

  useEffect(() => {
    if( startTime !== null && cursorPosition !== null) {
      setRecording(prev => [...prev, {ms: new Date().getTime() - startTime.getTime(), pos: cursorPosition}]);
    }
  }, [startTime, cursorPosition]);


  const handleKeyPress = useCallback((e) => {
    if (isFinished && e.key === "Enter") {
      pushEvent('next_by_enter', {
        'event_category': 'Practice',
        'event_label': 'next test by enter'
      });
      reset();
      return;
    }

    if (!isFinished && e.key === "Escape") {
      pushEvent('refresh_by_escape', {
        'event_category': 'Practice',
        'event_label': 'refesh test by escape'
      });
      reset();
      return;
    }

    if (e.key === "r" && isFinished) {
      pushEvent('retry_by_r', {
        'event_category': 'Practice',
        'event_label': 'retry test by r key'
      });
      doRetry();
      return;
    }
    if (!isFinished) {
      const nextChar = text[userInput.length];
      if (e.key === 'Backspace' || e.key === 'Delete') {
        setUserInput(prev => prev.slice(0, -1));
        return;
      }
      if (!allowedKeys(e.key)) {
        return;
      }

      if (e.key === nextChar) {
        setCorrectChars(prev => prev + 1);
      } else {
        setInCorrectChars(prev => prev + 1);
      }

      const newInput = userInput + e.key;
      setUserInput(newInput);
      if (newInput.length === text.length) {
        setIsFinished(true);
      }
    }
  }, [isFinished, text, userInput, doRetry, reset]);

  const calculateAccuracy = useCallback(() => {
    if (userInput.length === 0) return 0;
    const totalChars = correctChars + inCorrectChars;
    if (totalChars === 0) return 100;
    return Math.min(100, Math.round((correctChars / totalChars) * 100));
  }, [userInput.length, correctChars, inCorrectChars]);

  const value = {
    text,
    setText,
    userInput,
    setUserInput,
    startTime,
    setStartTime,
    wordCount,
    setWordCount,
    isFinished,
    setIsFinished,
    correctChars,
    setCorrectChars,
    inCorrectChars,
    setInCorrectChars,
    recording,
    setRecording,
    cursorPositionReplay,
    retry,
    setRetry,
    replay,
    setReplay,
    material,
    setMaterial,
    // Include all the callback functions here
    doRetry,
    handleKeyPress,
    calculateAccuracy,
    completed,
    setCompleted,
    inputRef,
    reset,
    cursorPosition,
    setCursorPosition
  };

  return (
    <PracticeContext.Provider value={value}>
      {children}
    </PracticeContext.Provider>
  );
};

export const usePractice = () => {
  const context = useContext(PracticeContext);
  if (!context) {
    throw new Error('usePractice must be used within a PracticeProvider');
  }
  return context;
};
